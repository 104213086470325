import { inject, Injectable } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, Messaging, isSupported, onMessage } from 'firebase/messaging';
import { environment } from '../../environments/environment';
import { FirebaseApp } from '@angular/fire/app';
import { FirebaseMessagingDataPayload, FirebaseMessagingType } from '@billytics/models';
import { Store } from '@ngxs/store';
import { JobsGet } from '../features/dashboard/states/jobs/jobs.action';
import { combineLatestWith, lastValueFrom } from 'rxjs';
import { JobAnalysisGet } from '../features/dashboard/states/job-analysis/job-analysis.action';
import { ConnectedAccountsGet } from '../features/dashboard/states/connected-accounts/connected-accounts.action';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  #firebaseApp: FirebaseApp;
  #messaging: Messaging | undefined;
  #store = inject(Store);

  constructor() {
    this.#firebaseApp = initializeApp(environment.firebase.config);
    this.#initialiseMessaging();
  }

  async #initialiseMessaging() {
    const isSupported = await this.isNotificationSupported();
    if (isSupported) {
      this.#messaging = getMessaging(this.#firebaseApp);
    }
  }

  async isNotificationSupported() {
    const supported = await isSupported();
    return supported;
  }

  async listenToFirebaseMessaging() {
    const messagingSupported = await isSupported();
    if (!messagingSupported) {
      console.warn('Firebase messaging is not supported');
      return;
    }
    if (Notification.permission !== 'granted') {
      console.log('permission not granted for notifications');
      return;
    }
    this.#listen();
  }

  async requestPermission(): Promise<boolean> {
    const messagingSupported = await isSupported();
    if (!messagingSupported) {
      console.warn('Firebase messaging is not supported');
      return false;
    }

    const permission = await Notification.requestPermission();
    if (permission === 'denied') {
      return false;
    }
    const token = this.getMessagingToken();
    this.#listen();
    return true;
  }

  getMessagingToken() {
    if (!this.#messaging) {
      throw new Error('firebase messaging not initialized');
    }
    return getToken(this.#messaging, { vapidKey: environment.firebase.vapidKey });
  }

  #listen() {
    if (!this.#messaging) {
      throw new Error('firebase messaging not initialized');
    }
    onMessage(this.#messaging, (payload) => {
      console.log('handling message', payload);
      this.handleFirebaseMessage(<FirebaseMessagingDataPayload | undefined>payload.data);
    });
  }

  private async handleFirebaseMessage(payload: FirebaseMessagingDataPayload | undefined) {
    console.log('payload', payload);
    if (!payload) {
      console.warn('empty data payload');
      return;
    }
    switch (payload.type) {
      case FirebaseMessagingType.JOB_COMPLETED:
      case FirebaseMessagingType.JOB_EXTRACTION_COMPLETED:
      case FirebaseMessagingType.JOB_FAILED:
        await lastValueFrom(
          this.#store
            .dispatch(new JobsGet())
            .pipe(combineLatestWith(this.#store.dispatch(new JobAnalysisGet()))),
        );
        break;
      case FirebaseMessagingType.CONNECTED_ACCOUNT_UPDATED:
        await lastValueFrom(this.#store.dispatch(new ConnectedAccountsGet()));
        break;
      default:
        break;
    }
  }
}
