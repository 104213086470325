import { inject, Injectable, NgZone } from '@angular/core';
import { Action, NgxsAfterBootstrap, Selector, State, StateContext, Store } from '@ngxs/store';

const tailwindSizes = {
  'sm': 640,
  'md': 768,
  'lg': 1024,
  'xl': 1280,
  '2xl': 1536,
};

class LayoutStateCheckView {
  static readonly type = '@LayoutState.CheckView';
}

interface LayoutStateModel {
  'xsm': boolean;
  'sm': boolean;
  'md': boolean;
  'lg': boolean;
  'xl': boolean;
  '2xl': boolean;
  '3xl': boolean;
}

@State<LayoutStateModel>({
  name: 'LayoutState',
  defaults: {
    'xsm': false,
    'sm': false,
    'md': false,
    'lg': false,
    'xl': false,
    '2xl': false,
    '3xl': false,
  },
})
@Injectable()
export class LayoutState implements NgxsAfterBootstrap {
  #ngZone = inject(NgZone);
  #store = inject(Store);

  @Selector()
  static isSm(state: LayoutStateModel) {
    return state.sm;
  }

  @Selector()
  static isMd(state: LayoutStateModel) {
    return state.md;
  }

  @Selector()
  static isLg(state: LayoutStateModel) {
    return state.lg;
  }

  @Selector()
  static isXl(state: LayoutStateModel) {
    return state.xl;
  }

  @Selector()
  static is2Xl(state: LayoutStateModel) {
    return state['2xl'];
  }

  onResize(event: Event) {
    this.#store.dispatch(new LayoutStateCheckView());
  }

  ngxsAfterBootstrap(ctx: StateContext<any>): void {
    ctx.dispatch(new LayoutStateCheckView());
    this.#ngZone.runOutsideAngular(() => {
      window.addEventListener('resize', this.onResize.bind(this));
    });
  }

  @Action(LayoutStateCheckView)
  checkViewport(ctx: StateContext<LayoutStateModel>) {
    const sizes = {
      'xsm': window.innerWidth <= tailwindSizes.sm,
      'sm': window.innerWidth >= tailwindSizes.sm,
      'md': window.innerWidth >= tailwindSizes.md,
      'lg': window.innerWidth >= tailwindSizes.lg,
      'xl': window.innerWidth >= tailwindSizes.xl,
      '2xl': window.innerWidth >= tailwindSizes['2xl'],
    };
    ctx.patchState(sizes);
  }
}
