export class ConnectedAccountsGetGoogleAuthorizationUrl {
  static readonly type = '@ConnectedAccountsState.getGoogleAuthorizationUrl';
  constructor(public options?: { connectedAccountId?: string }) {}
}

export class ConnectedAccountsGetMicrosoftAuthorizationUrl {
  static readonly type = '@ConnectedAccountsState.getMicrosoftAuthorizationUrl';
  constructor(public options?: { connectedAccountId?: string }) {}
}

export class ConnectedAccountsSaveNewGoogleAccount {
  static readonly type = '@ConnectedAccountsState.saveNewGoogleAccount';
  constructor(public code: string) {}
}

export class ConnectedAccountsSaveNewMicrosoftAccount {
  static readonly type = '@ConnectedAccountsState.saveNewMicrosoftAccount';
  constructor(public code: string) {}
}

export class ConnectedAccountsUpdateGoogleAccountTokens {
  static readonly type = '@ConnectedAccountsState.updateGoogleAccountTokens';
  constructor(
    public code: string,
    public connectedAccountId: string,
  ) {}
}

export class ConnectedAccountsUpdateMicrosoftAccountTokens {
  static readonly type = '@ConnectedAccountsState.updateMicrosoftAccountTokens';
  constructor(
    public code: string,
    public connectedAccountId: string,
  ) {}
}

export class ConnectedAccountsDeleteAccount {
  static readonly type = '@ConnectedAccountsState.deleteAccount';
  constructor(public connectedAccountId: string) {}
}

export class ConnectedAccountsGet {
  static readonly type = '@ConnectedAccountsState.get';
}

export class ConnectedAccountsWatchAccount {
  static readonly type = '@ConnectedAccountsState.watchAccount';
  constructor(public connectedAccountId: string) {}
}

export class ConnectedAccountsUnwatchAccount {
  static readonly type = '@ConnectedAccountsState.unwatchAccount';
  constructor(public connectedAccountId: string) {}
}
