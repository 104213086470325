import { Injectable, inject } from '@angular/core';
import {
  Auth,
  AuthError,
  UserCredential,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from '@angular/fire/auth';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import {
  AuthGetUserDetail,
  AuthLogin,
  AuthRegisterUserDevice,
  AuthSendEmailVerification,
  AuthSendForgotPasswordEmail,
  AuthSignOut,
  AuthSignUp,
  AuthUpdatePassword,
} from './auth.action';
import { finalize, from, map, switchMap, tap } from 'rxjs';
import { LoadingEnd, LoadingStart } from '../../core/loading.state';
import { Router } from '@angular/router';
import { AUTH_SCOPE_CONTEXT_KEY, AuthService } from './auth.service';
import { User } from '@billytics/models';
import { ToastService } from '../../core/toast.service';
import { v4 } from 'uuid';
import { FirebaseService } from '../../core/firebase.service';

enum AuthErrorType {
  FIREBASE = 'FIREBASE',
  WEB_APP = 'WEB_APP',
}

interface AuthStateModel {
  errors: { type: AuthErrorType; error: AuthError }[];
  accessToken: string | undefined;
  scopeContext: string | undefined;
  firebaseUser: any | undefined;
  user: User | undefined;
  deviceId: string | undefined;
  notificationsSupported: boolean | undefined;
  deviceNotificationPermission: 'granted' | 'denied' | 'default' | undefined;
}

@State<AuthStateModel>({
  name: 'AuthState',
  defaults: {
    errors: [],
    accessToken: undefined,
    scopeContext: undefined,
    firebaseUser: undefined,
    user: undefined,
    deviceNotificationPermission: undefined,
    notificationsSupported: undefined,
    deviceId: localStorage.getItem('messagingDeviceId') ?? undefined,
  },
})
@Injectable()
export class AuthState implements NgxsOnInit {
  private router = inject(Router);
  private auth = inject(Auth);
  private authServices = inject(AuthService);
  #firebaseService = inject(FirebaseService);
  private toastServices = inject(ToastService);

  async ngxsOnInit(ctx: StateContext<AuthStateModel>): Promise<void> {
    this.auth.onAuthStateChanged(async (user) => {
      if (user) {
        ctx.patchState({
          errors: [],
          firebaseUser: structuredClone(user.toJSON()),
          accessToken: await user.getIdToken(),
        });
      } else {
        ctx.patchState({
          firebaseUser: undefined,
          accessToken: undefined,
          errors: [],
        });
      }
    });
    const notificationsSupported = await this.#firebaseService.isNotificationSupported();
    ctx.patchState({
      notificationsSupported,
      deviceNotificationPermission: notificationsSupported ? Notification.permission : undefined,
    });
    if (notificationsSupported) {
      this.#firebaseService.listenToFirebaseMessaging();
    }
  }

  @Selector() static errors(state: AuthStateModel) {
    return state.errors;
  }

  @Selector() static firebaseUser(state: AuthStateModel) {
    return state.firebaseUser;
  }

  @Selector() static accessToken(state: AuthStateModel) {
    return state.accessToken;
  }

  @Selector() static user(state: AuthStateModel) {
    return state.user;
  }

  @Selector() static deviceNotificationPermission(state: AuthStateModel) {
    return state.deviceNotificationPermission;
  }

  @Selector() static deviceNotificationIsActive(state: AuthStateModel) {
    return state.deviceNotificationPermission === 'granted';
  }

  @Selector() static notificationsSupported(state: AuthStateModel) {
    return state.notificationsSupported;
  }

  @Action(AuthSignUp)
  signUp(ctx: StateContext<AuthStateModel>, action: AuthSignUp) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return from(
      createUserWithEmailAndPassword(this.auth, action.payload.email, action.payload.password),
    ).pipe(
      tap({
        error: (err: AuthError) => {
          ctx.patchState({
            errors: [{ error: err, type: AuthErrorType.FIREBASE }],
          });
        },
      }),
      switchMap(() => {
        return this.authServices.signUp({
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
        });
      }),
      tap(() => {
        this.router.navigate(['/auth'], { replaceUrl: true });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(AuthLogin)
  login(ctx: StateContext<AuthStateModel>, action: AuthLogin) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return from(
      signInWithEmailAndPassword(this.auth, action.payload.email, action.payload.password),
    ).pipe(
      tap({
        error: (err) => {
          ctx.patchState({
            errors: [{ error: err, type: AuthErrorType.FIREBASE }],
          });
        },
      }),
      switchMap((res: UserCredential) =>
        from(res.user.getIdToken()).pipe(map((token) => ({ res: res, accessToken: token }))),
      ),
      switchMap(({ res, accessToken }) => {
        ctx.patchState({
          firebaseUser: res.user.toJSON(),
          accessToken,
        });
        return this.authServices.getScopeContext().pipe(
          tap((scope) => {
            window.localStorage.setItem(AUTH_SCOPE_CONTEXT_KEY, scope.token);
            ctx.patchState({
              scopeContext: scope.token,
            });
          }),
          switchMap(() => from(this.router.navigate(['/portal'], { replaceUrl: true }))),
        );
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(AuthGetUserDetail)
  getUserDetail(ctx: StateContext<AuthStateModel>, action: AuthGetUserDetail) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.authServices.getAccountDetails(action.fetchUpdates).pipe(
      tap((user) => {
        ctx.patchState({ user });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(AuthSignOut)
  signOut() {
    return from(this.authServices.signOut());
  }

  @Action(AuthSendEmailVerification)
  sendEmailVerificationLink(ctx: StateContext<AuthStateModel>) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return from(this.authServices.sendEmailVerificationLink()).pipe(
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(AuthSendForgotPasswordEmail)
  sendForgotPasswordEmail(ctx: StateContext<AuthStateModel>, action: AuthSendForgotPasswordEmail) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return from(this.authServices.sendForgotPasswordEmail(action.email)).pipe(
      tap(() => this.toastServices.showToast('Password reset email has been sent to your email.')),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(AuthUpdatePassword)
  updatePassword(ctx: StateContext<AuthStateModel>, action: AuthUpdatePassword) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return from(this.authServices.updatePassword(action.currentPassword, action.newPassword)).pipe(
      tap({
        next: () => this.toastServices.showToast('Password has been changed successfully.'),
        error: (err) => {
          ctx.patchState({
            errors: [{ error: err, type: AuthErrorType.FIREBASE }],
          });
        },
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(AuthRegisterUserDevice)
  async registerUserDevice(ctx: StateContext<AuthStateModel>, action: AuthRegisterUserDevice) {
    try {
      const consentGiven = await this.#firebaseService.requestPermission();
      ctx.patchState({
        deviceNotificationPermission: Notification.permission,
      });
      if (!consentGiven) {
        return;
      }
    } catch (err) {
      console.error(err);
    }

    const { deviceId } = ctx.getState();
    let registeredDeviceId = deviceId;
    if (!registeredDeviceId) {
      registeredDeviceId = v4();
      ctx.patchState({
        deviceId: registeredDeviceId,
      });
      localStorage.setItem('messagingDeviceId', registeredDeviceId);
    }

    const payload = {
      userAgent: navigator.userAgent,
      registeredDeviceId,
      firebaseMessagingToken: await this.#firebaseService.getMessagingToken(),
    };
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.authServices
      .registerDevice(payload)
      .pipe(finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))));
  }
}
