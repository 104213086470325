import { Job } from '@billytics/models';

export class JobsGet {
  static readonly type = '@JobsState.get';
}

export class JobsCreate {
  static readonly type = '@JobsState.create';
  constructor(public payload: Pick<Job, 'type' | 'jobPayload' | 'ignoreDuplicates'>) {}
}

export class JobsRequeueJob {
  static readonly type = '@JobsState.requeueJob';
  constructor(public jobId: string) {}
}
