import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { FmErrorHandlerService } from './fm-error-handler.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './error-interceptor.service';
import { LoadingState } from './loading.state';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { LayoutState } from './layout.state';

@NgModule({
  declarations: [],
  imports: [NgxsModule.forFeature([LoadingState, LayoutState])],
  providers: [
    {
      provide: ErrorHandler,
      useClass: FmErrorHandlerService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'CAD' },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it at bootstrapApplication level only');
    }
  }
}
