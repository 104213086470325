import { inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  #snackBar = inject(MatSnackBar);

  showToast(message: string, action?: string, config?: MatSnackBarConfig): any {
    const ref = this.#snackBar.open(message, action ?? 'Ok', {
      duration: 5000,
      verticalPosition: 'top',
      ...config,
    });
    return ref;
  }
}
